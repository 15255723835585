<template>
  <div>
    <div class="w-fit mx-auto d-flex flex-row flex-wrap justify-content-center align-items-center">
      <div
        v-if="!newAccommodation"
        class="d-flex flex-row flex-wrap justify-content-center align-items-center w-100"
      >
        <div class="mx-3 mt-3 mb-3 fs-5">
          {{ $tcust('CheckingForm.selectAccommodation') }}
        </div>
        <div
          v-if="AccommodationsListLoaded"
          class="CheckingFormAccSelect w-100 flex-grow-1"
        >
          <MDBSelect
            ref="CheckingFormAccSelect"
            @click="newAccommodation = false"
            @change="lastChecking = null; AccommodationId ? newAccommodation = false : ''"
            filter
            filterDebounce="200"
            clearButton
            :visibleOptions="7"
            :label="$tcust('CheckingForm.select.accommodation')"
            v-model:options="AccommodationsList"
            v-model:selected="AccommodationId"
            :preselect="false"
            optionHeight="48"
            :searchPlaceholder="$tcust('common.form.select.searchPlaceholder')"
          />
        </div>
        <div v-else>
          <Loader />
        </div>
      </div>
      <div class="d-flex flex-row align-items-center mt-3 mt-sm-0">
        <div class="mx-3 mt-3 mb-3 fs-5">
          {{ $tcust('CheckingForm.orCreate') }}
        </div>
        <button
          id="NewAccButton"
          class="btn btn-outline-primary fs-5 text-normal py-1 h-fit shadow"
          @click.prevent="lastChecking = null; $refs.CheckingFormAccSelect ? $refs.CheckingFormAccSelect.setValue(null) : ''; newAccommodation = !newAccommodation"
        >
          <span>{{ newAccommodation ? $tcust('common.form.cancel') : $tcust('CheckingForm.newAddress') }}</span>
        </button>
      </div>
    </div>

    <div v-if="AccommodationId" class="mt-2">
      <Accommodation
        :accommodation="$store.state.accommodationsList.find(acc => acc.Id === AccommodationId)"
        :appearance="'List'"
        :key="AccommodationId"
      />
    </div>
  </div>
</template>

<script setup>
import {
  MDBSelect
} from "mdb-vue-ui-kit";
import Accommodation from "@/components/summary/accommodation/Accommodation";

import { computed, defineEmits, defineProps, onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

import { i18n } from '@/plugins/i18n';
import { mutateApp } from '@/helpers'

const route = useRoute();
const store = useStore();

const emit = defineEmits(['dataChanged']);

const props = defineProps({
  fill: {
    type: Number,
    default: null
  }
});
const fill = computed(() => {
  return props.fill
});

const AccommodationsList = ref([]);
const AccommodationsListLoaded = ref(false);
const AccommodationId = ref("");

const newAccommodation = ref(false);
const CheckingFormAccSelect = ref(null);

const lang = computed(() => {
  return route.params.lang;
});

const chargeAccList = () => { // Mise en forme du select
  let list = [];
  for (let acc of store.state.accommodationsList) {
    list.push({
      text: acc.Address.FullAddress,
      value: acc.Id,
      secondaryText: `${i18n.global.t('functions.contraction', { message: `
        ${i18n.global.t('accommodation.reference')} ${acc.Reference} \xa0\xa0 
        ${i18n.global.t('accommodation.type', { n: acc.RoomsCount })} \xa0\xa0 
        ${i18n.global.t('accommodation.surface', { n: acc.Surface })} \xa0\xa0 
        ${i18n.global.t('specific.floor', { number: acc.FloorNumber }).replace('<sup>', '').replace('</sup>', '')}
      ` })}`
    })
  }
  AccommodationsList.value = list;
};

onMounted(() => {
  store.dispatch("getAllAccommodations")
  .then(() => {
    chargeAccList();
    return
  })
  .then(() => {
    return AccommodationsListLoaded.value = true
  })
  .then(() => {
    if (fill.value) {
      CheckingFormAccSelect.value.setOption(fill.value)
    }
  })
});

watch(lang, () => {
  chargeAccList();
});
watch(AccommodationId, () => {
  emit('dataChanged', AccommodationId.value);
});

const modal = computed(() => {
  return store.state.app.modal;
});

watch((newAccommodation), async () => {
  return mutateApp('modal', newAccommodation.value ? "AccommodationForm" : false)
});
watch(modal, () => {
  if (!modal.value) {
    newAccommodation.value = false;
    AccommodationId.value = store.state.accommodationChosen ? store.state.accommodationChosen.Id : null;
  }
});
watch(fill, () => {
  if (CheckingFormAccSelect.value) CheckingFormAccSelect.value.clear();
  if (fill.value) {
      CheckingFormAccSelect.value.setOption(fill.value)
    }
});
</script>

<style lang="scss" scoped>
.CheckingFormAccSelect {
  max-width: 400px !important;
}
</style>
