<template>
  <div>
    <MDBDatepicker
      :label="$tcust('CheckingForm.appointment.date')"
      :title="$tcust('CheckingForm.appointment.date')"
      :placeholder="$tcust('common.form.datePicker.displayedFormat')"
      v-model="AppointmentDate"
      @change="$event.target.reportValidity() ? '' : AppointmentDate = null"
      @keypress.enter="$event.target.reportValidity()"
      class="mb-3"
      required
      :selfOpen="true"
      :startDay="1"
      :pattern="datePattern"
      :format="$tcust('common.form.datePicker.dateFormat')"
      :okBtnLabel="$tcust('common.form.datePicker.okBtnLabel')"
      :clearBtnLabel="$tcust('common.form.datePicker.clearBtnLabel')"
      :cancelBtnLabel="$tcust('common.form.datePicker.cancelBtnLabel')"
      :okBtnText="$tcust('common.form.confirm')"
      :clearBtnText="$tcust('common.form.clear')"
      :cancelBtnText="$tcust('common.form.cancel')"
      :nextMonthLabel="$tcust('common.form.datePicker.nextMonthLabel')"
      :prevMonthLabel="$tcust('common.form.datePicker.prevMonthLabel')"
      :nextYearLabel="$tcust('common.form.datePicker.nextYearLabel')"
      :prevYearLabel="$tcust('common.form.datePicker.prevYearLabel')"
      :nextMultiYearLabel="$tcust('common.form.datePicker.nextMultiYearLabel')"
      :prevMultiYearLabel="$tcust('common.form.datePicker.prevMultiYearLabel')"
      :switchToMultiYearViewLabel="$tcust('common.form.datePicker.switchToMultiYearViewLabel')"
      :switchToDayViewLabel="$tcust('common.form.datePicker.switchToDayViewLabel')"
      :monthsFull="$tcust('common.form.datePicker.monthsFull').split(',')"
      :monthsShort="$tcust('common.form.datePicker.monthsShort').split(',')"
      :weekdaysFull="$tcust('common.form.datePicker.weekdaysFull').split(',')"
      :weekdaysShort="$tcust('common.form.datePicker.weekdaysShort').split(',')"
      :weekdaysNarrow="$tcust('common.form.datePicker.weekdaysNarrow').split(',')"
    />
    <MDBTimepicker
      :label="$tcust('CheckingForm.appointment.time')"
      :placeholder="$tcust('common.form.timePicker.hoursPlaceholder')"
      v-model="AppointmentTime"
      required
      :selfOpen="true"
      :disabled="!AppointmentDate"
      :icon="!!AppointmentDate"
      :increment="5"
      :amLabel="$tcust('common.form.timePicker.amLabel')"
      :pmLabel="$tcust('common.form.timePicker.pmLabel')"
      :okLabel="$tcust('common.form.confirm')"
      :clearLabel="$tcust('common.form.clear')"
      :cancelLabel="$tcust('common.form.cancel')"
      :invalidLabel="$tcust('common.form.timePicker.invalidLabel')"
      :hoursFormat="parseInt($tcust('common.form.timePicker.hoursFormat'))"
    />
    <div
      v-if="AppointmentDate && AppointmentTime"
      class="m-auto mt-3 text-center"
    >
      <span v-html="$tcust('appointment', { 'date': AppointmentDateTime })" />
    </div>
  </div>
</template>

<script setup>
import {
  MDBDatepicker,
  MDBTimepicker,
} from "mdb-vue-ui-kit";

import { computed, defineEmits, defineProps, onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
// import { useStore } from "vuex";

import { i18n } from '@/plugins/i18n';

// const store = useStore();
const route = useRoute();
const emit = defineEmits(['dataChanged']);

const props = defineProps({
  fill: {
    type: Object,
  }
});
const fill = computed(() => {
  return props.fill
});

const lang = computed(() => {
  return route.params.lang;
});

const AppointmentDate = ref("");
const AppointmentTime = ref("");
const AppointmentDateTime = ref("");
const datePattern = i18n.global.t('common.form.datePicker.dateFormat').replace('DD', '\\d{2}').replace('MM', '\\d{2}').replace('YYYY', '\\d{4}');


onMounted(async () => {
  if (fill.value) {
    AppointmentDate.value = fill.value.AppointmentDate;
    AppointmentTime.value = fill.value.AppointmentTime;
  }
});

watch(AppointmentDate, () => {
  if (AppointmentDate.value) {
    if (AppointmentDate.value[1] === '/') AppointmentDate.value = "0" + AppointmentDate.value
    let format = i18n.global.t('common.form.datePicker.dateFormat');
    let year = AppointmentDate.value.substring(format.indexOf("Y"), format.lastIndexOf("Y") + 1);
    let month = AppointmentDate.value.substring(format.indexOf("M"), format.lastIndexOf("M") + 1) - 1;
    let day = AppointmentDate.value.substring(format.indexOf("D"), format.lastIndexOf("D") + 1);
    AppointmentDateTime.value = new Date(year, month, day);
    if (AppointmentTime.value) {
      AppointmentDateTime.value.setHours(AppointmentTime.value.substring(0, 2));
      AppointmentDateTime.value.setMinutes(AppointmentTime.value.substring(3, 5));
    }
  }
  else AppointmentDateTime.value = null
});
watch(AppointmentTime, () => {
  if (AppointmentTime.value) {
    if (AppointmentTime.value[1] === ':') AppointmentTime.value = "0" + AppointmentTime.value
    AppointmentDateTime.value.setHours(AppointmentTime.value.substring(0, 2));
    AppointmentDateTime.value.setMinutes(AppointmentTime.value.substring(3, 5));
  }
  else AppointmentDateTime.value = null
});
watch(AppointmentDateTime, () => {
  if (AppointmentDate.value && AppointmentTime.value) {
    emit('dataChanged', AppointmentDateTime.value);
  }
});
// watch(lang, () => {
//   AppointmentDate.value = null;
//   AppointmentTime.value = null;
// });
watch(fill, () => {
  if (fill.value) {
    AppointmentDate.value = new Date(fill.value).toLocaleDateString(lang.value);
    AppointmentTime.value = new Date(fill.value).toLocaleTimeString(lang.value, { hour: 'numeric', minute: 'numeric' });
  }
});
</script>

<style lang="scss">
@import "styles/var.scss";

.datepicker-header, .timepicker-head, .datepicker-cell.selected .datepicker-cell-content, .timepicker-time-tips-minutes.active, .timepicker-time-tips-inner.active, .timepicker-time-tips-hours.active, .timepicker-hand-pointer, .timepicker-middle-dot {
  background-color: $dark-theme-primary !important;
  color: $dark;
}
.datepicker-title-text, .datepicker-footer-btn, .datepicker-date-text, .timepicker-clear, .timepicker-cancel, .timepicker-submit {
  color: $dark;
}
.timepicker-hand-pointer, .timepicker-circle {
  border-color: $dark-theme-primary !important;
}
.timepicker-head-content {
  background-color: rgba($dark, 0.5);
  border-radius: 1rem;
}
</style>
