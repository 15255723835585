<template>
  <div>
    <div v-for="name of AnnexesList.sort()" :key="name">
      <div class="d-flex flex-row align-items-start mb-2">
        <div class="col-5 text-end pe-3">
          {{ $tcust("checking.annexes." + name, { n: AnnexesInputs[name+'Number'] && AnnexesInputs[name+'Number'].length ? AnnexesInputs[name+'Number'].length : 0 }) }}
        </div>
        <div class="col-7 custom-inside-max-size d-flex flex-row">
          <MDBInput
            :id="name"
            @keyup.enter="addAnnexe(name)"
            class="text-end tiny"
          >
          <div v-if="AnnexesInputs[name+'Number'] && AnnexesInputs[name+'Number'].length">
            <hr class="w-75 m-auto mb-1" />
            <div class="p-1 pb-0">
              <MDBBadge
                v-for="(value, index) of AnnexesInputs[name+'Number']"
                :key="index"
                class="position-relative border border-2 m-1 my-0 fs-6 fw-normal py-1 pe-4 text-wrap"
                @click.prevent="removeAnnexe(name, index)"
              >
                {{ value }}
                <MDBIcon
                  icon="times"
                  icon-style="fas"
                  size="sm"
                  class="position-absolute m-2 top-0 end-0 opacity-50"
                  type="button"
                  style="cursor: pointer; margin-top: 0.33rem !important; margin-right: 0.33rem !important"
                  tabindex="0"
                  :title="$tcust('common.form.clear')"
                  @click.prevent="removeAnnexe(name, index)"
                  @keydown.space.prevent="removeAnnexe(name, index)"
                />
              </MDBBadge>
            </div>
          </div>
          </MDBInput>
          <div class="AnnexesList-button">
            <button
              class="btn btn-outline-primary py-0 px-1"
              @click.prevent="addAnnexe(name)"
              :title="$tcust('common.form.validate')"
            >
              <MDBIcon
                icon="check"
                icon-style="fas"
                size="md"
              />
            </button>
            <button
              v-if="AnnexesInputs[name+'Number'] && AnnexesInputs[name+'Number'].length"
              class="btn btn-outline-primary py-0 px-1"
              @click.prevent="removeAllAnnexe(name)"
              style="padding-top: 2px !important"
              :title="$tcust('common.form.clearAll')"
            >
              <MDBIcon
                icon="times"
                icon-style="fas"
                size="md"
                style="transform: scale(1.2)"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  MDBBadge,
  MDBIcon,
  MDBInput
} from "mdb-vue-ui-kit";

import { defineEmits, defineProps, onMounted, reactive, ref, watch } from "vue";
// import { useRouter } from "vue-router";
// import { useStore } from "vuex";

// const router = useRouter();
// const store = useStore();

const props = defineProps({
  fill: {
    type: Array,
  }
});
const fill = ref(props.fill);

const emit = defineEmits(['dataChange']);

const Annexes = reactive({});
const AnnexesInputs = reactive({});
const AnnexesList = ref([
  "cellar",
  "storeRoom",
  "attic",
  "indoorParking",
  "outdoorParking",
  "box",
  "garage"
]);
const addAnnexe = (name, string) => {
  let value = string || document.getElementById(name).value;
  if (!value) return
  if (value === ',') setTimeout(() => {
    for (let input of document.querySelectorAll('#Annexes input')) {
      input.value = null;
    }
    return
  });
  let pending;
  if (value.indexOf(',') > -1) { // handling comma
    pending = value;
    value = value.slice(0, value.indexOf(','));
    pending = pending.replace(value, '').slice(1).trim();
  }
  if (value) {
    let Name = name+'Number';
    AnnexesInputs[Name] && AnnexesInputs[Name].length ? AnnexesInputs[Name].push(value.trim()) : AnnexesInputs[Name] = [value.trim()];
    if (!pending) setTimeout(() => {
      for (let input of document.querySelectorAll('#Annexes input')) {
        input.value = null;
      }
    });
  }
  if (pending) {
    document.getElementById(name).value = pending;
    addAnnexe(name);
  }
};
const removeAnnexe = (name, index) => {
  AnnexesInputs[name+'Number'].splice(index, 1);
  setTimeout(() => {
    for (let input of document.querySelectorAll('#Annexes input')) {
      input.value = null;
    }
  });
};
const removeAllAnnexe = (name) => {
  AnnexesInputs[name+'Number'] = "";
  setTimeout(() => {
    for (let input of document.querySelectorAll('#Annexes input')) {
      input.value = null;
    }
  });
};
const parseAnnexes = () => {
  for (let [key, value] of Object.entries(AnnexesInputs)) {
    key = key.charAt(0).toUpperCase() + key.slice(1);
    Annexes[key] = value.toString();
  }
};
const fillAnnexes = () => {
  if (fill.value && !fill.value.IsEmpty) {
    for (let [key, value] of Object.entries(fill.value)) {
      if (value) addAnnexe(key.replace('Number', '').toLowerCase(), value);
    }
  }
};

onMounted(() => {
  fillAnnexes();
});

watch(Annexes, () => {
  parseAnnexes();
  emit('dataChange', Annexes);
});
watch(fill, () => {
  fillAnnexes();
});
</script>

<style lang="scss" scoped>
@import "styles/var.scss";

.light-theme input + div {
  background-color: $gray-100;
}
.dark-theme input + div {
  background-color: rgba($gray-900, 0.3);
}
.badge {
  background-color: rgba($light-theme-primary, 0.1);
  &:hover, &:focus {
    background-color: rgba($light-theme-primary, 0.3);
    cursor: pointer
  }
}
.AnnexesList-button {
  width: 1.6rem;
  button:first-child {
    height: 1.8rem;
    width: 1.5rem;
  }
  button:nth-child(2) {
    height: 1.5rem;
    width: 1.5rem;
  }
}
.custom-inside-max-size {
  max-width: 250px !important;
}
</style>
