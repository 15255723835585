<template>
  <form
    id="CheckingFormform"
    class="p-3 p-md-4 pt-2"
    onsubmit="return false"
    :key="$route.name"
  >
    <div class="fs-3 mb-4 m-auto w-fit">
      {{ route.name === 'CheckingPost' ? $tcust('CheckingForm.title.new') : $tcust('CheckingForm.title.update') }}
    </div>

    <!-- License -->
    <div class="w-fit m-auto mb-4 d-flex flex-row flex-wrap align-items-end">
      <div class="ms-2 fs-5 w-fit me-3">
        {{ $tcust('CheckingForm.license') }}
      </div>
      <BigLicenseSwitcher class="mt-3 mx-auto" />
    </div>

    <hr class="w-75 text-primary my-3 mx-auto" />

    <!-- Address -->
    <SelectOrCreateAccommodation
      @data-changed="AccommodationId = $event"
      :fill="fillAccommodationId"
    />

    <!-- <div
      v-if="newAccommodation"
      class="mb-5 border rounded-5 shadow-3-strong"
    >
      <AccommodationForm class="mx-n3 mb-n4" />
    </div> -->

    <hr class="w-75 text-primary my-3 mx-auto" />

    <CardContainer
      class="m-auto h-fit w-100"
      :class="$store.state.licenseChosenType === 0 ? 'gradient' : 'gradient-group'"
      :impose-appearance="'List'"
    >
      <div class="w-100 d-flex flex-row flex-wrap p-0">
        <!-- Type -->
        <div
          class="inside-block w-fit flex-grow-1"
        >
          <div class="bg-box p-3">
            <div class="ms-3 fs-5">
              {{ $tcust('checking.check') }}
            </div>
            <div class="p-2">
              <div class="custom-inside-size d-flex flex-row flex-wrap justify-content-around w-100">
                <button
                  id="TypeDefault"
                  class="w-fit btn fs-6 m-2 shadow"
                  :class="Type === 1 ? 'btn-primary' : 'btn-outline-primary color-current'"
                  @click.prevent="Type = 1"
                >
                  <span>{{ $tcust("checking.in") }}</span>
                </button>
                <button
                  class="w-fit btn fs-6 m-2 shadow"
                  :class="Type === 0 ? 'btn-secondary' : 'btn-outline-secondary'"
                  @click.prevent="Type = 0"
                >
                  <span>{{ $tcust("checking.out") }}</span>
                </button>            
              </div>
            </div>
          </div>
        </div>

        <!-- Appointment -->
        <div
          class="inside-block w-fit flex-grow-1"
        >
          <div class="bg-box p-3">
            <div class="ms-3 fs-5">
              {{ $tcust('CheckingForm.appointment.title') }}
            </div>
            <DateTimePicker
              class="custom-inside-size m-auto my-3"
              @data-changed="AppointmentDateTime = $event"
              :fill="fillAppointmentDateTime"
            />
          </div>
        </div>
      </div>
    </CardContainer>

    <hr class="w-75 text-primary my-3 mx-auto" />

    <CardContainer class="gradient w-100 m-auto">
      <div class="d-flex flex-row flex-wrap p-0">
        <div
          class="inside-block w-fit flex-grow-1 flex-md-grow-0"
        >
          <div class="bg-box p-3">
            <div class="custom-inside-size">
              <div class="ms-3 fs-5 d-flex flex-row">
                {{ $tcust('CheckingForm.title.roomsCount') }}
              </div>
              <div class="mt-3 w-100 m-auto">
                <div class="d-flex flex-row flex-wrap align-items-end justify-content-center mb-2">
                  <div class="px-5">
                    <MDBInput
                      id="RoomsCount"
                      :label="$tcust('CheckingForm.checking.count')"
                      v-model="RoomsCount"
                      type="number"
                      class="text-center tiny"
                      required
                    />
                  </div>
                  <div class="mt-3 w-100 fst-italic text-muted">
                    {{ $tcust('CheckingForm.checking.explanations') }}
                  </div>
                  <div class="mt-3 w-100 fst-italic text-muted">
                    {{ $tcust('CheckingForm.checking.exteriors') }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          id="Annexes"
          class="inside-block w-fit flex-grow-1"
        >
          <div class="bg-box d-flex">
            <div class="custom-inside-size flex-grow-1 p-3">
              <div class="ms-3 fs-5 d-flex flex-row">
                {{ $tcust('CheckingForm.title.addAnnexes') }}
              </div>
              <div class="ms-3 fst-italic text-muted mb-2">
                {{ $tcust('CheckingForm.annexe.explanations') }}
              </div>
              <div class="p-3 py-2 w-100 m-auto">
                <div class="w-100">
                  <AnnexesList
                    @data-change="Annexes = $event"
                    :fill="lastChecking ? lastChecking.Annexes : null"
                    :key="lastChecking"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="d-flex flex-row flex-wrap w-100"
        >
          <div
            class="inside-block flex-grow-1 w-fit"
          >
            <div class="bg-box pb-2">
              <div class="ms-3 fs-5 pt-2 d-flex flex-row w-100">
                {{ $tcust('CheckingForm.title.owners') }}
              </div>

              <SelectAndCreateProfils
                :select_label="$tcust('CheckingForm.select.owner')"
                :multiple="false"
                :fill="lastChecking ? lastChecking.fill.Owners : null"
              />
            </div>
          </div>

          <div
            class="inside-block flex-grow-1 w-fit"
          >
            <div class="bg-box pb-2">
              <div class="ms-3 fs-5 pt-2 d-flex flex-row w-100">
                {{ $tcust('CheckingForm.title.occupants') }}
              </div>

              <SelectAndCreateProfils
                :select_label="$tcust('CheckingForm.select.occupant')"
                :fill="lastChecking ? lastChecking.fill.Occupants : null"
              />
            </div>
          </div>
        </div>

        <div
          class="d-flex flex-row flex-wrap w-100"
        >
          <div
            class="inside-block flex-grow-1 w-fit"
          >
            <div class="bg-box pb-2">
              <div class="ms-3 fs-5 pt-2 d-flex flex-row w-100">
                {{ $tcust('CheckingForm.title.experts') }}
              </div>

              <SelectAndCreateProfils
                :select_label="$tcust('CheckingForm.select.expert')"
                :multiple="false"
              />
            </div>
          </div>

          <div
            class="inside-block flex-grow-1 w-fit"
          >
            <div class="bg-box pb-2">
              <div class="ms-3 fs-5 pt-2 d-flex flex-row w-100">
                {{ $tcust('CheckingForm.title.representatives') }}
              </div>

              <SelectAndCreateProfils
                :select_label="$tcust('CheckingForm.select.representative')"
                :fill="lastChecking ? lastChecking.fill.Representatives : null"
              />
            </div>
          </div>
        </div>
      </div>
    </CardContainer>

    <!-- Submit button -->
    <div class="d-flex justify-content-center p-4">
      <MDBBtn
        color="primary"
        block
        class="w-fit px-5 fw-bold"
        type="submit"
        @click="submit($event)"
        @keyup.enter="submit($event)"
      >
        <span>{{ $tcust("common.form.validate") }}</span>
      </MDBBtn>
    </div>
  </form>
</template>

<script setup>
import {
  MDBBtn,
  MDBInput
} from "mdb-vue-ui-kit";
import BigLicenseSwitcher from "@/components/ui/BigLicenseSwitcher.vue";
// import AccommodationForm from "@/views/AccommodationForm.vue";
import AnnexesList from "@/components/form/AnnexesList.vue";
import DateTimePicker from "@/components/form/DateTimePicker.vue";
import SelectAndCreateProfils from "@/components/form/SelectAndCreateProfils.vue";
import SelectOrCreateAccommodation from "@/components/form/SelectOrCreateAccommodation.vue";

import { computed, onMounted, onUpdated, reactive, ref, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";

import { i18n } from '@/plugins/i18n';
import { mutateApp, mutateKey } from '@/helpers'

const router = useRouter();
const route = useRoute();
const store = useStore();

const AccommodationsList = ref([]);
const AccommodationsListLoaded = ref(false);
const AccommodationId = ref("");

const newAccommodation = ref(false);
const fillAccommodationId = ref(null);

const Type = ref("");
const AppointmentDateTime = ref("");
const Image = ref("");
const RoomsCount = ref("");
const Annexes = ref("");

const data = reactive({
  Type,
  AppointmentDateTime,
  Licenses: {
    LicenseKey: store.state.licenseChosen.LicenseKey,
  },
  Accomodation: AccommodationId,
  Image,
  RoomsCount,
  Annexes
});

const submit = async (event) => {
  if (Type.value  === null || Type.value === "") {
    event.preventDefault();
    return document.querySelector("#TypeDefault").focus()
  }
  if (AccommodationId.value === null || AccommodationId.value === "") {
    event.preventDefault();
    return document.getElementById('NewAccButton').focus();
  }
  if (!document.getElementById('CheckingFormform').reportValidity()) {
    return event.preventDefault()
  }
  await store
    .dispatch("checkingAddOrUpdate", data)
    .then(() => {
      router.push({ name: 'CheckingsList' })
    })
};

// const lang = computed(() => {
//   return route.params.lang;
// });
const lastCheckingLoaded = ref(true);
const lastChecking = ref(null);

const chargeAccList = () => { // Mise en forme du select
  let list = [];
  for (let acc of store.state.accommodationsList) {
    list.push({
      text: acc.Address.FullAddress,
      value: acc.Id,
      secondaryText: `${i18n.global.t('functions.contraction', { message: `
        ${i18n.global.t('accommodation.reference')} ${acc.Reference} \xa0\xa0 
        ${i18n.global.t('accommodation.type', { n: acc.RoomsCount })} \xa0\xa0 
        ${i18n.global.t('accommodation.surface', { n: acc.Surface })} \xa0\xa0 
        ${i18n.global.t('specific.floor', { number: acc.FloorNumber }).replace('<sup>', '').replace('</sup>', '')}
      ` })}`
    })
  }
  AccommodationsList.value = list;
};

const fillAppointmentDateTime = ref(null);
const fillData = () => {
  if (route.name === 'CheckingPost') {
      mutateKey('checkingChosen', null)
    }
  if (store.state.checkingChosen) {
    setTimeout(() => {
      Type.value = store.state.checkingChosen.Type || null;
      fillAppointmentDateTime.value = store.state.checkingChosen.AppointmentDateTime || null;
      Image.value = store.state.checkingChosen.Image || null;
      RoomsCount.value = store.state.checkingChosen.RoomsCount || null;
      Annexes.value = store.state.checkingChosen.Annexes || null;

      fillAccommodationId.value = null; // To react
      if (store.state.checkingChosen.Accomodation.AccomodationId) fillAccommodationId.value = store.state.checkingChosen.Accomodation.AccomodationId;
    }, 200);
  }
};

onMounted(() => {
  store.dispatch("getAllAccommodations")
  .then(() => {
    chargeAccList();
    return
  })
  .then(() => {
    return AccommodationsListLoaded.value = true
  })
  .then(() => {
    return fillData()
  });
});

onUpdated(() => {
  store.dispatch("getAllAccommodations")
  .then(() => {
    chargeAccList();
    return
  })
  .then(() => {
    return AccommodationsListLoaded.value = true
  })
  .then(() => {
    return fillData()
  });
});

const fillList = (data) => { // Cause each person in res is a different Obj
  let Owners = [data.Owner.Id];
  let Occupants = [];
  for (let pers of data.Occupants) {
    Occupants.push(pers.PersonId);
  }
  let Representatives = null;
  (() => {
    let res = [];
    const getChild = (parent) => {
      res.push(parent.Current.Id)
      if (parent.Child) {
        if (parent.Child.length > 0) for (const child of parent.Child) {
          getChild(child)
        }
        else getChild(parent.Child)
      }
    }
    getChild(data.Representatives)
    return Representatives = res
  })();
  let Experts = [data.Expert.Id];
  return {
    Owners,
    Occupants,
    Representatives,
    Experts
  }
};

// watch(lang, () => {
//   chargeAccList();
// });
watch(AccommodationId, async () => {
  if (AccommodationId.value) {
    lastCheckingLoaded.value = false;
    await store.dispatch("getChecking", 1)
    .then((res) => {
      res.Annexes = {
        AtticNumber: "C1, C2, C5",
        BoxNumber: null,
        CellarNumber: null,
        GarageNumber: null,
        IndoorParkingNumber: null,
        IsEmpty: false,
        OutdoorParkingNumber: null,
        StoreRoomNumber: null,
      };
      res['fill'] = fillList(res);
      if (Type.value === null) Type.value = res.Type === 0 ? 1 : 0;
      // if (!AppointmentDateTime.value) AppointmentDateTime.value = res.AppointmentDateTime;
      RoomsCount.value = res.RoomsCount;
      return lastChecking.value = res;
    })
    .then(() => {
      return lastCheckingLoaded.value = true
    })
  }
  else {
    Type.value = null;
    AppointmentDateTime.value = null;
    RoomsCount.value = null;
    lastChecking.value = null;
  }
});

const modal = computed(() => {
  return store.state.app.modal;
});

watch((newAccommodation), async () => {
  return mutateApp('modal', newAccommodation.value ? "AccommodationForm" : false)
});
watch(modal, () => {
  if (!modal.value) newAccommodation.value = false;
});
</script>

<style lang="scss" scoped>
.gradient, .gradient-group {
  max-width: 700px;
  // & .inside-block {
  //   max-width: 100% !important;
  // }
  // & .inside-block:not(.w-100) {
  //   min-width: 270px;
  // }
}
.custom-inner-shadow {
  box-shadow: inset 0 0 15px 3px rgb(0 0 0 / 9%);
}
.custom-inside-size {
  width: 250px;
  margin: auto;
  max-width: 90vw;
}
.custom-input {
  max-width: 75px !important;
  & input {
    padding-right: 5px !important;
  }
}
img {
  max-width: 250px;
  max-height: 250px;
}
span.badge {
  cursor: pointer;
}

@media (max-width: 768px) {
  .gradient {
    max-width: 400px;
  }
}
@media (max-width: 359.98px) {
  .p-3 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
}

#app.light-theme {
  .btn-outline-primary.text-normal {
    font-weight: normal !important;
  }
}
</style>
