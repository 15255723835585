<template>
  <div>
    <div v-if="!loaded">
      <Loader />
    </div>
    <div v-else>
      <div class="p-3 pb-0 d-flex flex-column align-items-center">
        <div class="mb-3 ms-2 me-auto">
          {{ $tcust('common.form.select.title') }}
        </div>
        <div class="text-muted m-auto w-100 h-fit px-3">
          <MDBSelect
            id="Persons"
            ref="PersonsSelect"
            filter
            filterDebounce="200"
            clearButton
            :multiple="props.multiple"
            :visibleOptions="7"
            :label="props.select_label"
            v-model:options="PersonsList"
            v-model:selected="PersonsSelected"
            :preselect="false"
            :searchPlaceholder="$tcust('common.form.select.searchPlaceholder')"
            :noResultsText="$tcust('common.form.select.noResultsText')"
            :selectAllLabel="$tcust('common.form.select.selectAllLabel')"
            displayedLabels="2"
            :optionsSelectedLabel="$tcust('common.form.select.optionsSelectedLabel')"
          />
        </div>
      </div>

      <div class="d-flex flex-column justify-content-center align-items-center mb-3">
        <div
          v-if="Persons"
          class="d-flex flex-column justify-content-start"
        >
          <div
            v-for="person of Persons"
            :key="person.Id"
            class="custom-inside-infos mt-3 border border-current rounded-5 overflow-hidden"
          >
            <ProfilInfos
              :person="person"
            />
          </div>
          <div class="w-50 mb-sm-3" />
        </div>

        <hr class="m-auto w-75" />

        <div
          v-if="NewPersons.length"
          class="d-flex flex-column justify-content-start"
        >
          <div
            v-for="(person, index) of NewPersons"
            :key="index"
            class="custom-inside-infos mt-3 border border-current rounded-5"
          >
            <ProfilInfos
              :key="person"
              :person="person"
              :delete_button="true"
              @delete="NewPersons.splice(index, 1);"
            />
          </div>
        </div>

        <button
          class="btn btn-outline-primary mt-3"
          @click.prevent="NewPersonForm ? NewPersonForm = null : NewPersonForm = {}"
        >
          <span>{{ NewPersonForm ? $tcust('common.form.cancel') : $tcust('common.form.newPerson') }}</span>
        </button>

        <!-- <div
          v-if="NewPersonForm"
          class="custom-inside-infos-new mx-n5 mb-n3"
        >
          <ProfilUpdate
            :sub_form="true"
            @dataValided="addNewPerson($event)"
            class="mx-sm-n3"
          />
        </div> -->
      </div>
    </div>
  </div>
</template>

<script setup>
import { MDBSelect } from "mdb-vue-ui-kit";
// import ProfilUpdate from "@/views/ProfilUpdate.vue";

import { computed, defineEmits, defineProps, onMounted, reactive, ref, watch } from "vue";
import { useStore } from "vuex";
import { mutateApp } from '@/helpers'

const props = defineProps({
  select_label: { 
    type: String,
    default: null,
  },
  multiple: { 
    type: Boolean,
    default: true,
  },
  fill: {
    type: Array,
  }
});
const fill = computed(() => {
  return props.fill
});

const emit = defineEmits(['dataChanged']);
const store = useStore();

const loaded = ref(false);
const storeList = computed(() => {
  return store.state.personsList;
});

const PersonsSelect = ref(null);

const PersonsList = ref([]);
const PersonsSelected = ref("");
const Persons = computed(() => {
  let personsList;
  try {
    personsList = PersonsSelected.value.split(',')
  } catch (error) {
    personsList = [PersonsSelected.value];
  }
  let resList = [];
  if (PersonsSelected.value) {
    for (let Id of personsList) {
      resList.push(storeList.value.find(obj => obj.Id == Id));
    }
  }
  return resList
});

const NewPersons = ref([]);
const NewPersonForm = ref("");

const addNewPerson = (data) => {
  if (!props.multiple) {
    PersonsSelected.value = null;
    NewPersons.value = [];
  }
  NewPersons.value.push(data);
  NewPersonForm.value = null;
};
// const deletePerson = (person) => {
//   PersonsSelected.value = PersonsSelected.value.replace(new RegExp(person.Id + ',*', 'g'), '');
//   if (PersonsSelected.value.slice(-1) === ',') PersonsSelected.value = PersonsSelected.value.slice(0, -1);
// };

const data = reactive({
  Persons,
  NewPersons
});

onMounted(async () => {
  await store
    .dispatch("getAllPersons")
    .then(() => {
      let list = [];
      for (let person of storeList.value) {
        list.push({
          text: person.FullName,
          value: person.Id,
          icon: person.Image ? 'data:image/png;base64,' + person.Image : 'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill-opacity="0.4"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 128c39.77 0 72 32.24 72 72S295.8 272 256 272c-39.76 0-72-32.24-72-72S216.2 128 256 128zM256 448c-52.93 0-100.9-21.53-135.7-56.29C136.5 349.9 176.5 320 224 320h64c47.54 0 87.54 29.88 103.7 71.71C356.9 426.5 308.9 448 256 448z"/></svg>' // icon: camera
        });
      }
      list = list.sort((a, b) => a.text.localeCompare(b.text));
      PersonsList.value = list;
      return
    })
    .then(() => {
      return loaded.value = true
    })
    .then(() => {
      if (fill.value) {
        for (let Id of fill.value) {
          let opt = PersonsList.value.findIndex(pers => pers.value === Id);
          if (opt > -1) PersonsSelect.value.setOption(opt);
        }
      }
    })
});

watch(data, () => {
  emit('dataChanged', data);
});
watch(fill, () => {
  if (PersonsSelect.value) PersonsSelect.value.clear();
  if (fill.value) {
    for (let Id of fill.value) {
      let opt = PersonsList.value.findIndex(pers => pers.value === Id);
      if (opt > -1) PersonsSelect.value.setOption(opt);
    }
  }
});

const modal = computed(() => {
  return store.state.app.modal;
});
const waitData = ref(false);
const modalStash = computed(() => {
  return { ...store.state.modalStash };
});

watch((NewPersonForm), async () => {
  waitData.value = NewPersonForm.value;
  return mutateApp('modal', NewPersonForm.value ? "ProfilUpdate" : false)
});
watch(modal, () => {
  if (!modal.value) NewPersonForm.value = false;
});
watch(modalStash, () => {
  if (waitData.value) {
    addNewPerson(modalStash.value);
    NewPersonForm.value = false;
  }
});
</script>

<style lang="scss" scoped>
.custom-inside-infos {
  width: 260px;
  margin: auto;
}
.custom-inside-infos-new {
  width: 330px !important;
  margin: auto;
  @media (max-width: 571.98px) {
    border: none !important;
    box-shadow: none !important;
  }
}
#app.light-theme {
  .btn-outline-primary.text-normal {
    font-weight: normal !important;
  }
}
</style>
